<template>
  <div
    class="check-ppga-type"
    @click.stop.prevent
  >
    <SRadio
      v-model="afterpayVaultType"
      :label="checkedLabel"
      toggle
      theme="icon"
      gap="0"
      inline
      class="check-ppga-radio"
    >
      <span class="desc">{{ language.SHEIN_KEY_PC_30645 }}</span>
    </SRadio>
  </div>
</template>

<script setup>
import { SRadio } from '@shein-aidc/sui-radio'
import { computed } from 'vue'
const props = defineProps({
  afterpayVault: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  checkedLabel: {
    type: [String, Number, Boolean],
    default: ''
  }
})
const emits = defineEmits(['toggle'])

const afterpayVaultType = computed({
  get() {
    return props.afterpayVault.type
  },
  set() {
    emits('toggle')
  }
})
</script>

<style lang="less">
.check-ppga-type {
  padding: 4px 0;
  .flexbox();
  align-items: center;
  justify-content: flex-start;
  .desc {
    line-height: 20px;
    font-size: 12px;
  }
  .check-ppga-radio {
    .sui-radio__check {
      .margin-r(4px);
    }
  }
}
</style>
